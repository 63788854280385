<template>
  <div>
    <v-container fluid>
      <v-widget title="Estoque Depósito">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="10" sm="5">
                <v-text-field
                  filled
                  flat
                  single-line
                  hide-details
                  dense
                  clearable
                  rounded
                  v-model="busca"
                  @click:clear="limparBusca"
                  @keyup.enter="filtrarEstoques($event)"
                  max-width="300px"
                  prepend-inner-icon="search"
                  label="Buscar Produto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="estoques" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.produto.descricao }}</td>
                <td class="text-center">{{ props.item.estoque }}</td>
                <td class="text-center">{{ props.item.valor | formatarMoeda }}</td>
                <td class="text-center">{{ props.item.filial.cnpj | formatarCnpj }}</td>
                <td class="text-center">{{ props.item.filial.fantasia }}</td>
                <td class="text-center">
                  <v-icon @click="detalharEstoque(props.item)">mdi-text-box-search-outline</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>

    <dialog-detalhar-estoque-deposito ref="dialogDetalharEstoqueDeposito"></dialog-detalhar-estoque-deposito>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import DialogDetalharEstoqueDeposito from './DialogDetalharEstoqueDeposito';

export default {
  name: 'ListaEstoqueDeposito',
  components: {
    DialogDetalharEstoqueDeposito,
  },
  data() {
    return {
      headers: [
        { text: 'Produto', value: 'produto.descricao', sortable: false },
        { text: 'Estoque', value: 'estoque', align: 'center', sortable: false },
        { text: 'Valor', value: 'valor', align: 'center', sortable: false },
        { text: 'CNPJ', value: 'filial.cnpj', align: 'center', sortable: false },
        { text: 'Empresa', value: 'filial.fantasia', align: 'center', sortable: false },
        { text: 'Detalhes', value: 'name', align: 'center', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.carregarEstoques();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('estoqueDeposito', {
      estoques: 'estoquesDeposito',
      carregando: 'carregando',
      total: 'totalEstoques',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    carregarEstoques() {
      this.$store.dispatch('estoqueDeposito/carregar', this.options);
    },

    limparBusca() {
      this.carregarEstoques();
    },

    filtrarEstoques(event) {
      let newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('estoqueDeposito/carregar', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    detalharEstoque(estoque) {
      this.$refs.dialogDetalharEstoqueDeposito.abrir(estoque);
    },
  },
};
</script>
